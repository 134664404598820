.loader-container {
  width: 100%;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.601);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
}

#loader-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: 'center';
}

.loader-image {
  width: 40px;
  width: 40px;
}

.svgbox {
  --blue: rgb(148, 66, 63);
  stroke: var(--purple);
  stroke-width: 5;
  fill: none;
  stroke-dasharray: 50, 14;
  stroke-dashoffset: 192;
  animation: dash_682 1.4s linear infinite;
}

#loader-content .MuiTypography-root {
  display: inline-block;
  backface-visibility: hidden;
  transform-origin: center;
  transform-style: preserve-3d;
  transition: transform 0.6s ease-in-out, opacity 0.6s ease-in-out;
}

@keyframes dash_682 {
  72.5% {
    opacity: 1;
  }

  to {
    stroke-dashoffset: 1;
  }
}
