.navbar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 15px 25px;
  z-index: 99;
  background-color: #fff;
  position: fixed;
  top: 0;
}

.nav-brand {
  width: 25%;
}

.home-open-drawer {
  width: 100% !important;
  display: none;
  justify-content: space-between;
  align-items: center;
  padding: 20px 25px;
  background-color: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  flex-direction: row;
}

.nav-brand-img {
  cursor: pointer;
  width: 120px;
}

.nav-links-main {
  width: 40%;
  height: 56px;
  /* background-color: yellowgreen; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-links-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* background-color: violet; */
  /* box-shadow: 1px 1px 7.3px 0px rgba(185, 185, 185, 0.25); */
  border-radius: 16px;
  /* border: 2px solid rgba(249, 249, 249, 1); */
  background-color: #fff;
}

.nav-links {
  padding: 10px;
  text-decoration: none;
  margin: 5px 10px;
  color: rgba(156, 156, 168, 1);
  font-size: 16px;
  margin-right: 20px;
  position: relative;
  text-wrap: nowrap;
}

.nav-buttons {
  width: 35%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.nav-list-property-btn {
  background: linear-gradient(131.14deg, #7e0db4 1.71%, #590db4 100%);
  width: 180px;
  box-shadow: 1px 1px 7.3px 0px rgba(185, 185, 185, 0.25);
  font-size: 14px !important;
  border-radius: 16px;
  color: #fff;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 5px;
  cursor: pointer;
  margin: 0 30px;
  border-radius: 8px;
}

.nav-add-icon {
  margin-right: 5px;
}

.nav-button {
  width: 46px;
  height: 46px;
  background-color: rgba(255, 255, 255, 1);
  margin: 0 5px;
  border: 2px solid rgba(249, 249, 249, 1);
  box-shadow: 1px 1px 7.3px 0px rgba(185, 185, 185, 0.25);
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.active-nav-link {
  color: rgba(96, 13, 180, 1);
  font-weight: 700;
}

.home-profile-content {
  z-index: 999;
  width: 150px;
  cursor: pointer;
}

.home-logout-icon {
  margin: 0 10px;
  cursor: pointer;
}

@media screen and (max-width: 650px) {
  .navbar {
    display: none;
  }

  .home-open-drawer {
    display: flex;
    justify-content: space-between;
    padding: 18px 8px;
  }

  .home-container {
  }
}
