
.advance-fil-container{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 550px;
    min-height:550px;
    background: #ffffff;
    box-shadow: 24px;
    border-radius: 10px;
    border:none;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    color: rgba(71, 71, 71, 1) ;
}

.advance-fil-content{
    padding:15px 20px;
    padding-bottom: 80px;
}

.adv-fil-Availability{
    width: 100%;
    overflow: scroll;
}

.adv-fil-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
  
}

.is-selected-fil{
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background-color: rgba(96, 13, 180, 1);
    display: block;
    margin-right: 5px ;
}

.advance-fil-header-btns{
    width: 100%;
    padding: 10px 0;
}

.advance-fil-btn{
    padding: 8px;
    margin: 8px;
    margin-left: 0;
    display: inline-block;
    border: 1px solid rgba(210, 210, 210, 1);
    border-radius: 8px;
    font-size: 14px;
    text-align: center;
    cursor: pointer;

}

.advance-fil-filters{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

}

.advance-fil-disp-list{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 5px;
    margin-bottom: 15px;
}

.advance-fil-item{
    display: inline-block;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid rgba(210, 210, 210, 1);
    color: rgba(125, 125, 125, 1);
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;

}

.active-advance-fil-item{
    background-color: rgba(245, 237, 255, 1);
    color: rgba(71, 71, 71, 1);
}

.advance-fil-list{
    width: 35%;
    margin-right: 8px ;

}

.active-advance-fil-list{
 width: 60%;
 max-height: 350px;
 overflow-y: scroll;
 overflow-x: hidden;
}



.active-fil-btn{
    background-color: #600DB4;
    color: rgba(254, 254, 254, 1);
}

.advance-fil-devider{
    border: 1px solid rgba(210, 210, 210, 0.544);


}

.advance-fil-footer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    width: 100%;
    bottom: 0;
    box-shadow: 0px -1px 7.8px 0px rgba(0, 0, 0, 0.1);

}

.advance-fil-details{
    font-size: 14px;
    color: rgba(71, 71, 71, 1);
    font-weight: 500;
    position: absolute;
    bottom: 55px;
    left: 20px;
    
}

.adv-fil-active-label{
    font-size: 14px;
    margin-bottom: 5px;
    color: #7d7d7d;
}

@media screen and (max-width: 500px) {
    .advance-fil-container{
        width: 100%;
    }

    .advance-fil-list{
        width: 40%;
    }
}