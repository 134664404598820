
  @font-face {
    font-family: "Inter Regular";
    font-style: normal;
    font-weight: 400;
    src: local(""), 
    url("./Inter_18pt-Regular.ttf") format("truetype"); /* Safari, Android, iOS */
    font-display: swap;
  }

  @font-face {
    font-family: "Inter Medium";
    font-style: normal;
    font-weight: 400;
    src: local(""), 
    url("./Inter_18pt-Medium.ttf") format("truetype"); /* Safari, Android, iOS */
    font-display: swap;
  }


  @font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    src: local(""), 
    url("./Poppins-Regular.ttf") format("truetype"); /* Safari, Android, iOS */
    font-display: swap;
  }

  @font-face {
    font-family: "Poppins Medium";
    font-style: normal;
    font-weight: 400;
    src: local(""), 
    url("./Poppins-Medium.ttf") format("truetype"); /* Safari, Android, iOS */
    font-display: swap;
  }

  