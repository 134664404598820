@import './Assets/fonts/fonts.css';

#root {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Inter Regular', 'Roboto', sans-serif !important;
  /* font-family:'Poppins'; */
  /* background: linear-gradient(156.46deg, #B98ACA -0.06%, #DEACE4 30.08%, #A1A7E5 67.2%, #366CB7 99.96%); */
  /* background: #E3E3E3; */
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
  background-color: #fbfbfb;
}

.font-regular {
  font-family: 'Inter Regular' !important;
}

.font-medium {
  font-family: 'Inter Medium' !important;
}
::-webkit-scrollbar {
  display: none;
}

.box-shadow {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.otp-verification-form,
.success-screen {
  background-color: white;
  color: #6200ea;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.otp-verification-title,
.success-title {
  font-weight: bold;
}

.otp-verification-subtitle,
.success-subtitle {
  margin-top: 10px;
  margin-bottom: 20px;
}

.otp-input-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.otp-input {
  width: 54px;
  height: 54px;
  margin-right: 5px;
  font-size: 18px;
  /* border: 1px solid #D2D2D2; */
}

.otp-submit-button,
.success-button {
  margin-top: 20px;
  width: 100%;
}

.otp-error {
  color: #a03c42;
  margin-bottom: 10px;
}

.otp-resend-link,
.otp-back-link {
  margin-top: 10px;
  color: #474747 !important;
}

.success-icon {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
}

.sidebar {
  background-color: #6200ea;
  color: white;
  width: 300px;
  padding: 20px;
}

.sidebar-header {
  margin-bottom: 20px;
}

.sidebar-title {
  font-weight: bold;
}

.sidebar-description {
  font-size: 14px;
  margin-top: 5px;
}

.sidebar-user {
  font-size: 14px;
  margin-top: 10px;
  color: #e0e0e0;
}

.sidebar-chats {
  margin-top: 20px;
}

.sidebar-chat-heading {
  font-size: 16px;
  margin-bottom: 10px;
}

.sidebar-chat-item {
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
  cursor: pointer;
}

.rotate180 {
  rotate: 180deg;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}
.error-boundary-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.error-boundary-content {
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 300px;
}

.error-boundary-title {
  color: #474747;
  font-size: 20px;
}

.error-boundary-btn {
  margin: 25px 0 !important;
}

.error-modal:focus {
  outline-width: 0;
  outline: none;
}

.width1-3 {
  width: 31% !important;
}
.w-1-2 {
  width: 50% !important;
}

.addmar-30 {
  margin-right: 25px !important;
}

.mtop-5 {
  margin-top: 5px;
}

.benefits-comingsoon {
  /* background-size: contain; */
  background: url('./Assets//images//benefits-comingsoon-bg.svg') no-repeat;
  background-position: center;
}
