.property-instruction-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.property-verify-instructions {
  border-radius: 16px;
  padding: 1rem;
  background: rgba(254, 254, 254, 1);
  box-shadow: 2px 2px 10px 0px rgba(170, 170, 170, 0.25);
}

.property-verify-instructions-info {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}

.property-verify-instructions-icon {
  width: 40px;
  height: 40px;
  margin-right: 5px;
}

.property-verify-instructions-title {
  color: rgba(71, 71, 71, 1);
  font-weight: 500;
  font-size: 16px;
}

.property-verify-instructions-list {
  padding-left: 20px;
  margin: 10px 5px;
  list-style-type: disc;
}

.property-verify-instructions-list-item {
  color: rgba(71, 71, 71, 1);
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
}
