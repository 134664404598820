* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.hero-section {
  height: 30vh;
  width: 100%;
  background-image: url('../../../Assets/images/ProfileBg.png');
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 80px;
  margin-top: 9rem;
  position: relative;
}

.sidebar-profile-container {
  margin-top: 3rem;
}

.profile-photo {
  width: 144px;
  height: 144px;
  position: relative;
}

.edit-profile-btn {
  text-transform: capitalize !important;
  font-size: 12px !important;
  color: #474747 !important;
  border: 1.5px solid #d2d2d2 !important;
  border-radius: 8px !important;
}

.share-profile-btn {
  text-transform: capitalize !important;
  font-size: 12px !important;
  color: white !important;
  border-radius: 8px !important;
  margin-left: 1rem !important;
}

.box {
  padding: 1rem;
  border-radius: 8px;
  margin: 1rem 0 !important;
  box-shadow: 1;
  background: #fff;
}

.view-all-btn {
  text-transform: capitalize !important;
  font-size: 15px !important;
  color: #600db4 !important;
  font-weight: 500;
  text-decoration-line: underline !important;
}

.edit-button {
  position: absolute !important;
  left: 100px !important;
  z-index: 90 !important;
  top: 80px !important;
}

.edit-icon {
  color: #474747 !important;
  border: 1px solid #d2d2d2 !important;
  border-radius: 50% !important;
  padding: 4px !important;
  background: white !important;
  font-size: 2rem !important;
  cursor: pointer !important;
}
