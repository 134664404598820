*{
    font-family: 'Inter Regular' !important;
}

.signup-login-form-container{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-image: url('../../Assets/images/aira-background.png');  */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.social-icons{
    width: 50px;
    height: 50px;
    margin:2px 5px;
    cursor: pointer;
}

.signup-text-color{
    color: #7D7D7D;
}

.sign-up-social-login-title{
    width: 100%;
    display: inline-block;
    display: flex;
    justify-content: flex-start;
    font-size: 14px;
}

.sign-up-devider{
 margin: 18px 0px !important;
}

.signup-login-form {
    padding: 20px;
    max-width: 550px;
    margin: auto;
    text-align: center;
    background: white;
    border-radius: 10px;
    max-height: 92vh;
    overflow-y: scroll;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 10px;
    position: absolute;
    top: 63%;
    left: 50%;
    transform: translate(-50%, -60%);
}

.signup-login-form-content{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-footer-text{
    font-size: 14px;
}

.sign-up-label{
    font-weight: 400;
    font-size: 14px;
    color:#9EA5AD;
    text-align: left;
    display: inline-block;
    width: 100%;
    margin-bottom: 5px;
}

.signup-btn{
    font-weight: 400 !important;
    margin-top: 10px !important ;
    margin: 25px auto !important;
    font-family: 'Inter Regular' !important;
    background: linear-gradient(95.14deg, #E1A3FF 8.5%, #590DB4 89.84%) !important;
    margin-top: 12px !important;

}

.footer-text-login{
    color: blue;
    cursor: pointer;
}

.sign-up-title{
    font-size: 20px;
    font-family: 'Inter Regular';
    font-weight: 400;
    margin: 20px 0;
    margin-bottom: 25px;
    display: inline-block;
    color:#7D7D7D;
}

.signup-logo-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px 0;
    margin-bottom: 0px;

}

.sign-up-brand-logo {
    width: 110px !important;
    margin-bottom: 12px ;
    align-self: center;
}

.sign-up-social-logins{
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.input-container {
    display: flex;
    align-items: center;
    border: 1px solid rgba(210, 210, 210, 1);
    border-radius: 8px;
    padding: 10px;
    background-color: transparent;
    width: 100%;
  }
  
  .flag-icon {
    width: 18px;
    height: 12px;
    margin-right: 5px;
    border-radius: 2px;
  }
  
  .country-code {
    font-size: 14px;
    color: #474747;
    margin-right: 8px;
    padding-right: 8px;
    border-right: 1px solid #ffffffaa;
  }
  
  .signup-inputs {
    border: none;
    color: #474747;
    background-color: transparent;
    outline: none;
    font-size: 14px;
  }
  
  .signup-inputs:focus {
    outline: none;
  }

  .signup-inputs::placeholder{
    color: #47474744 !important;
  }
  
  .signup-login-form-content input::placeholder {
    color: rgba(254, 254, 254, 0.405);
  }
  

.input-error{
    border: 1px solid #b52525 !important;
    
}

.signup-error-msg{
    color: rgb(255, 21, 0);
    text-align: left;
    font-size: 14px;
    width: 100%;
    display: inline-block;
    margin-left: 0;

}

.signup-login-form .MuiTextField-root {
    margin-bottom: 1rem;
}

.signup-login-form .MuiButton-root {
    margin-top: 1rem;
}

.signup-login-form .social-login-button {
    margin: 0.5rem;
}

@media screen and (max-width: 500px) {

  
.signup-login-form {
    width: 280px;
    top: 67%;
}

.signup-login-form-content{
    width: 100%;
    margin: 0 auto;
}

  }
