* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.login-container {
  width: 100% !important;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  background-image: url('../Assets/images/login-bg.jpg');
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.login-container-logo {
  position: absolute;
  top: 50px;
  left: 120px;
}

.login-content-section {
  width: 65%;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  color: #ffffff;
}

.back-btn {
  fill: #ffffff !important;
}

.login-back-button {
  position: absolute;
  top: 15px;
  left: 15px;
}

.login-back-button-icon {
  width: 18px;
  height: 12px;
  cursor: pointer;
}

.login-hero-section-info {
  font-size: 17px;
  width: 80%;
  font-weight: 500;
  color: #ffffff;
  display: inline-block;
  text-align: left;
  line-height: 24.2px;
  width: 60%;
}

.login-hero-section-info-vrefyd {
  font-weight: 700;
}

.login-input-container-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-footer-note {
  color: #fefefe;
  font-size: 16px;
}

.login-input-container {
  padding: 20px;
  width: 500px;
  text-align: center;
  background: white;
  border-radius: 10px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 54vh;
  background: linear-gradient(
    112.19deg,
    rgba(255, 255, 255, 0.5) 0.98%,
    rgba(255, 255, 255, 0) 99.94%
  );
  backdrop-filter: blur(5px);
  margin: 20px;
}

.login-hero-section {
  display: flex;
  width: 50%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 100px;
  /* position: absolute;
    top: 60px; */
  /* max-width: 500px; */
}

.login-hero-main-title {
  font-size: 36px;
  color: #ffffff;
  line-height: 48.09px;
  font-weight: 700;
  text-align: left;
  font-family: 'Inter Medium' !important;
  margin-bottom: 6px;
}

.login-input-content {
  width: 65%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: '120px';
}

.login-input-logo-section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.login-input-brand-logo {
  width: 160px;
  height: 65px;
}

.footer-note {
  font-size: 12px;
  color: #ffffff;
  font-weight: 600;
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-top: 20px;
}

@media screen and (max-height: 650px) {
}

@media screen and (max-width: 600px) {
  .login-container {
    flex-direction: column;
    padding-top: 80px;
    background-image: none;
    background-color: #ffffff;
  }

  .login-content-section {
    width: 90%;
    margin: 0 auto;
  }

  .login-input-container {
    width: 90%;
    margin-top: 20px;
  }

  .login-hero-section {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 0;
  }

  .login-hero-section-info {
    width: 100%;
    line-height: 16.94px;
    letter-spacing: -2%;
    align-items: center;
    justify-content: center;
    padding: 0px 3px;
    text-align: center;
    font-size: 16px;
  }

  .login-hero-main-title {
    display: inline-block;
    width: 100%;
    font-size: 22px;
    line-height: 35.57px;
    margin: 0 auto;
    margin-bottom: 10px;
    text-align: center;
  }

  .login-input-container-wrapper {
    width: 100%;
  }

  .login-container-logo {
    top: 0px;
    left: 30%;
  }
  .login-input-content {
    width: 85%;
  }
}
